import axios from 'axios'
import moment from 'moment'

const SEARCH_SERVICE = process.env.REACT_APP_SEARCH_SERVICE
const BATCH_FILE_SERVICE = process.env.REACT_APP_BATCH_FILE_SERVICE

class HarvestApi {
  constructor () {
    this.state = {
      params: 10
    }
  }

  getPaginationResults (apiQueryString, jwt, response, error) {
    console.log(`${SEARCH_SERVICE}${apiQueryString}`)
    this.sendRequest(`${SEARCH_SERVICE}${apiQueryString}`, 'GET', jwt, response, error)
  }

  getPublications (siteContext, jwt, response, error) {
    console.log('getPublications ' + siteContext)
    this.sendRequest(`${BATCH_FILE_SERVICE}/${siteContext}/pub_catalog`, 'GET', jwt, response, error)
  }

  getExport (publicationContextKey, requestedParams, jwt, response, error) {
    const params = this.getQueryString(requestedParams)
    console.log(`${BATCH_FILE_SERVICE}/${publicationContextKey}/batch_file?${params}`)
    this.sendRequest(`${BATCH_FILE_SERVICE}/${publicationContextKey}/batch_file?${params}`, 'GET', jwt, response, error)
  }

  getQueryString (searchParams) {
    let params = `dataSource=${searchParams.dataSource}`

    if (searchParams.dataSource === 'pure' || searchParams.dataSource === 'scopus') {
      params += `&siteKey=${searchParams.siteContext}`
    } else if (searchParams.siteKey) {
      params += `&siteKey=${searchParams.siteKey}`
    }
    if (searchParams.affiliationID) {
      const affiliationIDs = searchParams.affiliationID.map((ids) => {
        return `&affiliationID=${ids}`
      })
      params += affiliationIDs.join('')
    }
    if (searchParams.firstName) {
      params += `&authorFirstName=${encodeURIComponent(searchParams.firstName)}`
    }
    if (searchParams.lastName) {
      params += `&authorLastName=${encodeURIComponent(searchParams.lastName)}`
    }
    if (searchParams.authorId) {
      params += `&authorID=${searchParams.authorId}`
    }
    if (searchParams.orcidId) {
      params += `&orcid=${searchParams.orcidId}`
    }
    if (searchParams.includeUnmappedMetadata) {
      params += `&includeUnmappedMetadata=${searchParams.includeUnmappedMetadata}`
    }
    if (searchParams.includeSherpaRomeo) {
      params += `&includeSherpaRomeo=${searchParams.includeSherpaRomeo}`
    }
    if (searchParams.selectedPublicationId) {
      params += `&publicationId=${searchParams.selectedPublicationId}`
    }
    if (searchParams.totalResults) {
      params += `&numRecords=${searchParams.totalResults}`
    }
    if (searchParams.authorAffiliation) {
      params += `&authorAffiliation=${encodeURIComponent(searchParams.authorAffiliation)}`
    }
    if (searchParams.pubYearFrom) {
      params += `&pubYearEarliest=${encodeURIComponent(searchParams.pubYearFrom)}`
      if (!searchParams.pubYearTo) { params += `&pubYearLatest=${encodeURIComponent(new Date().getFullYear() + 1)}` }
    }
    if (searchParams.pubYearTo) {
      params += `&pubYearLatest=${encodeURIComponent(searchParams.pubYearTo)}`
      if (!searchParams.pubYearFrom) { params += '&pubYearEarliest=1' }
    }
    if (searchParams.identifyDuplicates) {
      params += `&identifyDuplicates=${searchParams.identifyDuplicates}`
    }
    if (searchParams.publicationType) {
      params += `&publicationType=${searchParams.publicationType}`
    }
    if (searchParams.organizationalUnitID) {
      params += `&orgUnitId=${searchParams.organizationalUnitID}`
    }
    if (searchParams.contentFilters) {
      const filters = searchParams.contentFilters.map((filter) => {
        return `&${filter}=1`
      })
      params += filters.join('')
    }
    if (searchParams.createDateFrom) {
      params += `&createdDateEarliest=${encodeURIComponent(searchParams.createDateFrom)}`
      if (!searchParams.createDateTo) {
        params += `&createdDateLatest=${encodeURIComponent(moment().format())}`
      }
    }
    if (searchParams.createDateTo) {
      params += `&createdDateLatest=${encodeURIComponent(searchParams.createDateTo)}`
      if (!searchParams.createDateFrom) {
        params += `&createdDateEarliest=${encodeURIComponent(moment('1970-01-01').format())}`
      }
    }
    if (searchParams.contentTypes) {
      const types = searchParams.contentTypes.map((type) => {
        return `&typeUri=${encodeURIComponent(type.uri)}`
      })
      params += types.join('')
    }
    if (searchParams.pubDateFrom) {
      params += `&pubDateEarliest=${encodeURIComponent(searchParams.pubDateFrom)}`
      if (!searchParams.pubDateTo) {
        params += `&pubDateLatest=${encodeURIComponent(moment().format())}`
      }
    }
    if (searchParams.pubDateTo) {
      params += `&pubDateLatest=${encodeURIComponent(searchParams.pubDateTo)}`
      if (!searchParams.pubDateFrom) {
        params += `&pubDateEarliest=${encodeURIComponent(moment('1970-01-01').format())}`
      }
    }
    if (searchParams.siteUrl) {
      params += `&siteUrl=${searchParams.siteUrl}`
    }
    return params
  }

  getAuthorHarvestSearch (searchParams, jwt, response, error) {
    const params = this.getQueryString(searchParams)
    // article end point
    this.sendRequest(`${SEARCH_SERVICE}/articles?${params}`, 'GET', jwt, response, error)
  }

  getAffiliationsSearch (dataSource, siteKey, authorAffiliation, jwt, response, error) {
    this.sendRequest(`${SEARCH_SERVICE}/affiliations?dataSource=${dataSource}&query=${escape(authorAffiliation)}&siteKey=${siteKey}`, 'GET', jwt, response, error)
  }

  getBatchFileRequests (jwt, response, error) {
    this.sendRequest(`${BATCH_FILE_SERVICE}/batch_file_requests`, 'GET', jwt, response, error)
  }

  getContentTypes (dataSource, siteKey, jwt, response, error) {
    this.sendRequest(`${SEARCH_SERVICE}/content_types?dataSource=${dataSource}&siteKey=${siteKey}`, 'GET', jwt, response, error)
  }

  postMultiOrcidExport (publicationContextKey, data, jwt, response, error) {
    this.sendRequest(`${BATCH_FILE_SERVICE}/${publicationContextKey}/batch_file`, 'POST', jwt, response, error, data)
  }

  sendRequest (url, method, jwt, response, error, data = {}) {
    axios({
      method: method,
      headers: { Authorization: 'Bearer ' + jwt },
      url: url,
      data: data
    }).then(response)
      .catch(error)
  }
}

export default new HarvestApi()
